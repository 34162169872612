import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgHttpCachingService } from 'ng-http-caching';
import { switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface User {
  created?: Date;
  updated?: Date;
  deleted?: Date;
  versionHistory?: null;
  version?: number;
  id?: string;
  keycloak?: string;
  businessModel?: string;
  business?: string;
  companyId?: string;
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  position?: string;
  role?: string;
  last_login?: Date;
  last_active?: Date;
  avatar?: string;
  headerImage?: string;
  verified: boolean;
  banned: boolean;
  registrationFinished?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });
  private ngHttpCachingService = inject(NgHttpCachingService);

  public getUser(userId: string) {
    return this.http.get<User>(
      environment.matchMakingApiUrl + `/v1/users/get/${userId}`,
      { headers: this.header }
    );
  }

  public getUserByKeycloakId(keycloakId: string) {
    return this.http.get<User>(
      environment.matchMakingApiUrl + `/v1/users/${keycloakId}`,
      { headers: this.header }
    );
  }

  public registerUser(user) {
    this.ngHttpCachingService.clearCacheByRegex(/users/);
    return this.http.post<User>(
      environment.matchMakingApiUrl + '/v1/registration',
      user,
      { headers: this.header }
    );
  }

  public putUser(user: User) {
    this.ngHttpCachingService.clearCacheByRegex(/users/);
    return this.http.put<User>(
      environment.matchMakingApiUrl + '/v1/users/',
      user,
      { headers: this.header }
    );
  }

  public postUser(user: User) {
    this.ngHttpCachingService.clearCacheByRegex(/users/);
    return this.http.post<User>(
      environment.matchMakingApiUrl + '/v1/users/',
      user,
      { headers: this.header }
    );
  }

  public deleteUser(userId: string) {
    this.ngHttpCachingService.clearCacheByRegex(/users/);
    return this.http.delete(
      environment.matchMakingApiUrl + `/v1/users/${userId}`,
      { headers: this.header }
    );
  }

  public getUsers() {
    return this.http.get<User[]>(environment.matchMakingApiUrl + '/v1/users/', {
      headers: this.header,
    });
  }

  public getUsersByCompany(companyId: string) {
    return this.http.get<User[]>(
      environment.matchMakingApiUrl + `/v1/users/?companyId=${companyId}`,
      { headers: this.header }
    );
  }

  public verifyUser(userId: string, isVerified: boolean) {
    return this.getUser(userId).pipe(
      switchMap((user) => this.putUser({ ...user, verified: isVerified }))
    );
  }
}
